"use client";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { getCookie } from "cookies-next";
import React, { useState, useEffect } from "react";
import Loader from "@/components/Loader";
import { useRouter } from "next/navigation";
import { logout } from "@/redux/slices/userSlice";

export const ApolloProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [baseUrl, setBaseUrl] = useState<string | null>(null);
  const handleLogout = () => {
    dispatch(logout());
    router.push("/login");
  };

  // Fetch base URL from config.json
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const data = await response.json();
        setBaseUrl(data.API_BASE_URL);
      } catch (error) {
        console.error("Failed to fetch config.json", error);
      }
    };

    fetchConfig();
  }, []);

  const token =
    useSelector((state: RootState) => state.user.token) || getCookie("token");

  if (!baseUrl) {
    // Render a loading state until the base URL is fetched
    return <Loader />;
  }

  const httpLink = createHttpLink({
    uri: baseUrl, // Use the dynamically fetched base URL
  });

  // Debug link for logging requests
  const debugLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      console.log(`Ending request for ${operation.operationName}`, data);
      return data;
    });
  });

  // Error handling link
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      // Check for 502 status code
      if (
        "statusCode" in networkError &&
        networkError.response.status === 502
      ) {
        // Using window.location for immediate redirect
        window.location.href = "/updated";
      }
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        message == "Not logged in!" ? handleLogout() : null;
      });
    }
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `JWT ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      debugLink,
      errorLink, // Add error handling link
      authLink.concat(httpLink),
    ]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
