import React from "react";
import "./Loader.css"; // Add this import

function Loader() {
  return (
    <section className="flex items-center justify-center w-full h-screen">
      <div className="loader">
        <span></span>
      </div>
    </section>
  );
}

export default Loader;
